.project-name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    color: white;
}


.work .card {
    --bs-card-bg: var(--color-green);
    --bs-body-color: var(--color-light);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.work .card:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.7);  
}