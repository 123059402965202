@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Trispace&display=swap');

:root {
  --font-family-primary: 'Trispace', sans-serif;
  --font-family-secondary: 'Open Sans', sans-serif;


  --color-green: #18241E;
  --color-light-green: #A3B18A;
  --color-light: #DAD7CD;
}