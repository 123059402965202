.profile {
    display: flex;
    padding: 0 5rem;
}

.about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.social-icons {
    width: 3.5rem;
}

@media (max-width: 992px) {
    .about-row {
        padding-top: 20dvh;
    }

    .profile {
        padding: 14rem;
        margin-bottom: 3rem;
    }
    .about-content {
        padding-top: 2rem;
    }

}

@media (max-width: 767px) {
    .profile {
        padding: 3rem;
    }
    .about-content {
        padding: 0;
    }
    .social-icons-stack {
        display: flex;
        justify-content: center;
    }
}